import React from 'react';
import './whatsapp.scss';
import common_data from '../../data/data.json';

const Whatsapp = ({ data, cls }) => {
	return (
		<div className={`whatsapp ${cls || ''}`}>
			<div className="whatsapp__image-box">
				<img src={data.image} alt="KF Expert"/>
			</div>
			<div className="whatsapp__info">
				<div className="whatsapp__info-title">{ data.text }</div>
				<div className="whatsapp__info-steps">
					{ data.steps && data.steps.length ? data.steps.map((el, index) =>
						<span key={el + index}>{index + 1}. {el}</span>
					) : null }
				</div>
				<a href={data.link.href} className="whatsapp__link">{data.link.text}</a>
				<div className="whatsapp__agreement">Нажимая на кнопку, Вы соглашаетесь с <a href={common_data.privacy_policy || 'https://kf.expert/privacy-policy'}>«Политикой конфиденциальности»</a></div>
			</div>
		</div>
	);
};

export default Whatsapp;
