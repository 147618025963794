import React from 'react';
import './characteristics.scss';

const Characteristics = ({ list }) => {
	return (
		<div className='characteristics'>
			{ list.map((el, index) =>
				<div className="characteristics__item" key={index+el.title}>
					<span>{ el.title }</span>
					<b>{ el.text }</b>
				</div>
			) }
		</div>
	);
};

export default Characteristics;
