import React from 'react';
import './header.scss';
import Svg from "../svg/Svg";
import Expert from "../expert/Expert";

const Header = ({ data, handleClick }) => {
	return (
		<header className='header'>
			<div className="header__logo">
				{ data.logo && data.isImage ? <img src={ data.logo } alt="" /> : <Svg name={ data.logo }/> }
			</div>
			{ data.expert && <Expert data={data.expert} cls='header__expert'/> }
			<div className="header__social-list">
				{ data.social.length ? data.social.map((link, index) =>
					link.button ?
						<button className={`header__social header__social--${link.icon.replace('#', '')}`}
						        key={index + link.icon}
						        name='open-popup'
						        aria-label='open popup'
										onClick={handleClick}>
							<Svg name={ link.icon }/>
						</button> :
						<a href={ link.href } target='_blank' className={`header__social header__social--${link.icon.replace('#', '')}`} key={index + link.icon}>
							<Svg name={ link.icon }/>
						</a>
				) : null }
			</div>
			<div className="header__call-box">
				<a href={`tel:${data.call.phone}`} className="header__phone">{data.call.phone}</a>
				<button type='button' className='header__call' onClick={handleClick}>{data.call.text}</button>
			</div>
		</header>
	);
};

export default Header;
