import React from 'react';
import './button.scss';

const Button = ({ type, text, cls, handlerClick }) => {
	return (
		<button className={`button ${cls || ''}`} type={type || 'button'} onClick={handlerClick}>{text || ''}</button>
	);
};

export default Button;
