import axios from 'axios';

export default class Api {
	static post(url, data = {}, config = {}) {
		if (process.env.NODE_ENV === 'development') {
			config.params = data;
			return axios.get(url, config);
		}

		return axios.post(url, data, config);
	}

	static get(url, data = {}) {
		return axios.get(url, {
			params: data,
		});
	}
}
