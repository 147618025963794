import React from 'react';
import Header from "../header/Header";
import Main from "../main/Main";
import Footer from "../footer/Footer";
import data from '../../data/data.json';
// import data from '../../data/data-v1.json';
import Modal from "../modal/Modal";
import ModalThanks from "../modal/thanks/ModalThanks";
import Background from '../background/Background';
import MainBox from "../main-box/MainBox";

const Screen = () => {
	const [showPopup, setShowPopup] = React.useState(false);
	const [showThanks, setShowThanks] = React.useState(false);
	const [bgInited, setBgInited] = React.useState(false);
	const [index, setIndex] = React.useState(0);
	const openPopup = () => {
		setShowPopup(prev => !prev);
	};
	const openThanks = () => {
		setShowPopup(false);
		setShowThanks(prev => !prev);
	};

	const onChangeIndex = (idx) => {
		setIndex(idx);
	};

	return (
		<>
			{ data.background.slides.length > 1 ?
					<Background slides={data.background.slides} time={data.background.time} cb={() => setBgInited(true)} v2={data.main.v_2} idx={index}/> :
				data.background.slides.length === 1 ?
					<div className="main__background-image-box">
						<img src={ data.background.slides[0].image } alt="KF Expert"/>
					</div> :
				null
			}
			<Header data={data.header} handleClick={openPopup}/>
			{ data.main && data.main.v_2 && <Main data={data.main} sendData={openThanks}/>}
			{ data.main && !data.main.v_2 && <MainBox data={data.main} sendData={openThanks} pagination={data.pagination} bg={bgInited} changeIndex={onChangeIndex}/>}
			<Footer data={data.footer}/>
			{ showPopup && <Modal data={data.popup} handleClick={openPopup} sendData={openThanks}/> }
			{ showThanks && <ModalThanks data={data.popup_thanks} handleClick={openThanks}/> }
		</>
	);
};

export default Screen;
