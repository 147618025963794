import React from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './slider.scss';
import Svg from "../svg/Svg";

SwiperCore.use([Navigation]);

const Slider = ({ slides, cls, fullscreen }) => {
	const [swiper, setSwiper] = React.useState(null);
	const nextTo = () => {
		swiper.slideNext();
	};
	return (
		<div className={`slider-box ${cls || ''}`}>
			<Swiper breakpoints={{
				320: {
					spaceBetween: 16,
				},
				768: {
					spaceBetween: 32,
				},
				1280: {
					spaceBetween: 24,
				}
			}} navigation loop={true} loopedSlides={3} className='slider' onSwiper={(s) => {
				setSwiper(s);
			}}>
				{ slides.map((el, index) =>
					<SwiperSlide className='slider__slide' key={el.title + index}>
						<div className="slider__up-title">{el['up-title']}</div>
						<div className="slider__title">{el.title}</div>
						<div className="slider__image-box">
							<button className='slider__fullscreen' type='button' onClick={() => fullscreen(index)}>
								<Svg name='#fullscreen'/>
							</button>
							<img src={el.image} alt="KF Expert"/>
						</div>
					</SwiperSlide>
				) }
			</Swiper>
			<div className="slider__next" onClick={nextTo}/>
		</div>
	);
};

export default Slider;
