import './App.css';
import Screen from "./components/screen/Screen";

function App() {
  return (
    <Screen/>
  );
}

export default App;
