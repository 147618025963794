import React from 'react';
import './svg.css';
import sprite from '../../svg/sprite.svg';

const Svg = (props) => {
	return (
		<svg className={`svg ${props.cls ? props.cls: ''}`}>
			<use href={sprite + props.name}/>
		</svg>
	);
};

export default Svg;
