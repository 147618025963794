import React from 'react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './slider-full.scss';

SwiperCore.use([Navigation, Pagination]);

const SliderFull = ({ slides, cls, startSlide, closePopup}) => {
	return (
		<Swiper navigation loop={true} loopedSlides={3} initialSlide={startSlide || 0} pagination={{ type: "fraction" }} className='slider-full'>
			<button className="slider-full__close" onClick={closePopup}/>
			{ slides.map((el, index) =>
				<SwiperSlide className='slider-full__slide' key={el.title + index}>
					<img src={el.image} alt="KF Expert"/>
					<div className="slider-full__title">{el.title}</div>
					<div className="slider-full__up-title">{el['up-title']}</div>
				</SwiperSlide>
			) }
		</Swiper>
	);
};

export default SliderFull;
