import React from "react";
import { Helmet } from "react-helmet";

const Application = ({ children, data }) => {
	return (
		<>
			<Helmet>
				<meta charSet="utf-8"/>
				<meta name="viewport" content="width=device-width, initial-scale=1"/>
				<link rel="icon" href={data.icon || '/static/favicon.ico'}/>
				{ data.icon_svg && <link rel="icon" href={data.icon_svg}/> }

				<meta name="og:site_name" content={data.title}/>
				<meta name="og:title" content={data.title}/>
				<meta name="description" content={data.description}/>
				<meta name="og:description" content={data.description}/>
				<meta name="og:url" content={`${data.url || '/'}`}/>
				<meta name="og:type" content={`${data.content || ''}`}/>
				<meta name="og:image" content={data.image}/>
				<meta name="og:image:width" content="968"/>
				<meta name="og:image:height" content="504"/>
				<title>{ data.title }</title>
			</Helmet>
			{ children }
		</>
	);
};

export default Application;
