import React, {useCallback} from 'react';
import './messenges.scss';

const Messengers = ({ cls, handleChange, active, prefix, isEn, data }) => {
  const setLabel = useCallback((text) => {
    switch (text) {
      case 'call':
        return isEn ? 'Call' : 'Телефон';
      case 'whatsapp':
        return 'Whatsapp';
      case 'telegram':
        return 'Telegram';
      case 'botim':
        return 'Botim';
      default:
        return 'Call';
    }
  }, [isEn]);

	return (
		<div className={`messengers ${cls ? cls : ''}`}>
			<div className="messengers__text">{ isEn ? 'Choose a contact method' : 'Выберете способ связи' }</div>
			{data && data.length ? (
				<div className="messengers__list">
					{ data.map((item) =>
						<div className='messengers__item' key={item}>
							<input type="radio" id={`messenger-${item}${prefix || ''}`} value={item} checked={active === item} onChange={(e) => handleChange(e)}/>
							<label htmlFor={`messenger-${item}${prefix || ''}`}>{setLabel(item)}</label>
						</div>
					) }
				</div>
			) : (
				<div className="messengers__list">
					<div className='messengers__item'>
						<input type="radio" id={`messenger-call${prefix || ''}`} value='call' checked={active === 'call'} onChange={(e) => handleChange(e)}/>
						<label htmlFor={`messenger-call${prefix || ''}`}>{isEn ? 'Call' : 'Телефон'}</label>
					</div>
					<div className='messengers__item'>
						<input type="radio" id={`messenger-whatsapp${prefix || ''}`} value='whatsapp' checked={active === 'whatsapp'} onChange={(e) => handleChange(e)}/>
						<label htmlFor={`messenger-whatsapp${prefix || ''}`}>Whatsapp</label>
					</div>
					<div className='messengers__item'>
						<input type="radio" id={`messenger-telegram${prefix || ''}`} value='telegram' checked={active === 'telegram'} onChange={(e) => handleChange(e)}/>
						<label htmlFor={`messenger-telegram${prefix || ''}`}>Telegram</label>
					</div>
				</div>
			)}
		</div>
	);
};

export default Messengers;
