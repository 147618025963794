import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import Application from "./components/application/application";
import data from './data/data.json';

if (data.colorTheme) {
	document.documentElement.style.setProperty('--color-theme', data.colorTheme);
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Application data={data.meta}><App/></Application>
);
// root.render(
// 	<App/>
// );
