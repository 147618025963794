import React from 'react';
import './main-box.scss';
import Form from "../form/Form";
import Timer from "../timer/Timer";
import Expert from "../expert/Expert";
import Characteristics from "../characteristics/characteristics";
import Pagination from "../pagination/Pagination";

const MainBox = ({ data, sendData, pagination, bg, changeIndex }) => {
	const [currentData] = React.useState(data);
	const [currentIndex, setCurrentIndex] = React.useState(0);

	const switchData = (index) => {
		setCurrentIndex(index);
		changeIndex(index);
	};

	return (
		<main className='main-box'>
			{ bg && <Pagination text={pagination.text} list={pagination.list} handleClick={(idx) => switchData(idx)}/> }
			<div className="main-box__content">
				<div className="main-box__info">
					<div className='main-box__title'>{data[currentIndex].title}</div>
					{ data[currentIndex].characteristics && data[currentIndex].characteristics.length && <Characteristics list={data[currentIndex].characteristics}/> }
					{ data[currentIndex].expert && <Expert data={data[currentIndex].expert} cls='main-box__expert expert--main'/> }
					{ currentData[currentIndex].timer && <Timer cls='main-box__timer' text='До старта продаж' dataEnd={currentData[currentIndex].timer.end}/> }
				</div>
				<Form data={currentData[currentIndex].form} image={currentData[currentIndex].form.image || false} cls='main-box__form' handleSendData={sendData}/>
			</div>
		</main>
	);
};

export default MainBox;
