import React from 'react';
import './modal-thanks.scss';

const ModalThanks = ({ data, handleClick }) => {
	const keyboardClick = (e) => {
		if (e && e.key && e.key === 'Escape') {
			handleClick();
		}
	};
	React.useEffect(() => {
		document.addEventListener('keydown', keyboardClick);
		document.body.classList.add('body-fixed');
		return () => {
			document.removeEventListener('keydown', keyboardClick);
			document.body.classList.remove('body-fixed');
		};
	}, []);

	return (
		<div className='modal-thanks' id='popup-thanks'>
			<div className="modal-thanks__layout" onClick={handleClick}/>
			<div className="modal-thanks__content">
				<div className="modal-thanks__title">{data.title}</div>
				<div className="modal-thanks__text">{data.text}</div>
				<button className="modal-thanks__close" onClick={handleClick} name='modal-close' aria-label='close modal'/>
			</div>
		</div>
	);
};

export default ModalThanks;
