import React from 'react';
import './timer.scss';
import { declOfNum } from "../../utils/utils";

const Timer = ({ dataEnd, text, cls }) => {
	const timerRef = React.useRef();
	const end = dataEnd.split(',').map(el => Number(el));
	const htmlString = value => ({ __html: `<div><span>${value}</span><span>${value + 1}</span></div>` });

	if (timerRef.current) {
		setTimeout(() => {
			timerRef.current.classList.remove('timer--not-init');
		}, 300);
	}

	const getTimeLeft = (year, month, day, hour, minute, second, milisecond) => {
		const currentDate = new Date();
		const expectedDate = new Date(year, month - 1, day, hour, minute, second, milisecond);
		const timeLeft = expectedDate.getTime() - currentDate.getTime();
		if (timeLeft > 0) {
			let s = timeLeft / 1000;
			let mins = s / 60;
			let hrs = mins / 60;
			let days = hrs / 24;

			let secondsLeft = Math.floor(s % 60);
			let minsLeft = Math.floor(mins % 60);
			let hrsLeft = Math.floor(hrs % 24);
			let daysLeft = Math.floor(days);

			return [ daysLeft, hrsLeft, minsLeft, secondsLeft];
		}
	};
	let timeLeft = getTimeLeft(end[0], end[1], end[2], end[3], end[4], end[5], 0);

	const [data, setData] = React.useState({
		days: timeLeft[1],
		daysText: 'дней',
		hours: timeLeft[2],
		hoursText: 'часов',
		minutes: timeLeft[3],
		minutesText: 'минут',
		seconds: timeLeft[4],
		secondsText: 'секунд'
	});

	const setTimer = () => {
		let timeLeft = getTimeLeft(end[0], end[1], end[2], end[3], end[4], end[5], 0);
		if (timeLeft) {
			setData({
				days: timeLeft[0],
				daysText: declOfNum(timeLeft[0], ['день', 'дня', 'дней']),
				hours: timeLeft[1],
				hoursText: declOfNum(timeLeft[1], ['час', 'часа', 'часов']),
				minutes: timeLeft[2],
				minutesText: declOfNum(timeLeft[2], ['минута', 'минуты', 'минут']),
				// seconds: timeLeft[3],
				// secondsText: declOfNum(timeLeft[3], ['секунда', 'секнды', 'секунд']),
			});
		} else {
			clearInterval(setId);
		}
	};
	const setId = setTimeout(setTimer, 1000);
	return (
		<div className={`timer timer--not-init ${cls || ''}`} ref={timerRef} onClick={() => setData((prev) => {
			return {...data, seconds: prev.seconds + 1};
		})}>
			<div className="timer__title">{text}</div>
			<div className="timer__list">
				<div className="timer__item">
					<div className="timer__box" dangerouslySetInnerHTML={htmlString(data.days)}/>
					<div className="timer__text">{ data.daysText }</div>
				</div>
				<div className="timer__item">
					<div className="timer__box" dangerouslySetInnerHTML={htmlString(data.hours)}/>
					<div className="timer__text">{ data.hoursText }</div>
				</div>
				<div className="timer__item">
					<div className="timer__box" dangerouslySetInnerHTML={htmlString(data.minutes)}/>
					<div className="timer__text">{ data.minutesText }</div>
				</div>
				{/*<div className="timer__item">*/}
				{/*	<div className="timer__box" dangerouslySetInnerHTML={htmlString(data.seconds)}/>*/}
				{/*	<div className="timer__text">{ data.secondsText }</div>*/}
				{/*</div>*/}
			</div>
		</div>
	);
};

export default Timer;
