import React from 'react';
import './pagination.scss';
import SwiperCore, { Navigation, Thumbs, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

SwiperCore.use([Navigation]);

const Pagination = ({ text, list, handleClick }) => {
	const [thumbsSwiper, setThumbsSwiper] = React.useState(null);

	return (
		<div className='pagination'>
			<div className="pagination__hidden-box">
				<Swiper className='pagination__list'
				        onSlideChange={(e) => handleClick(e.realIndex)}
				        thumbs={{ swiper: thumbsSwiper }}
				        modules={[Thumbs, Autoplay]}
				        loop={true}
				        autoplay={{
					        delay: 7000,
					        disableOnInteraction: false,
				        }}
								>
					{ list.map((el, index) =>
						<SwiperSlide className='pagination__hidden-item' key={el.image + index}></SwiperSlide>
					) }
				</Swiper>
			</div>
			{ text && <div className="pagination__text">{ text }</div> }
			<Swiper navigation className='pagination__list' slidesPerView='auto' onSwiper={setThumbsSwiper}>
				{ list.map((el, index) =>
					<SwiperSlide className='pagination__item' key={el.image + index}>
						<svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
							<circle cx="28" cy="28" r="27.5"/>
						</svg>
						<img src={el.image} alt="KF Expert"/>
					</SwiperSlide>
				) }
			</Swiper>
		</div>
	);
};

export default Pagination;
