import React from 'react';
import './modal.scss';
import Form from "../form/Form";
import Expert from "../expert/Expert";

const Modal = ({ data, handleClick, sendData }) => {
	const keyboardClick = (e) => {
		if (e && e.key && e.key === 'Escape') {
			handleClick();
		}
	};
	React.useEffect(() => {
		document.addEventListener('keydown', keyboardClick);
		document.body.classList.add('body-fixed');
		return () => {
			document.body.classList.remove('body-fixed');
			document.removeEventListener('keydown', keyboardClick);
		};
	}, []);

	return (
		<div className='modal'>
			<div className="modal__layout" onClick={handleClick}/>
			<div className="modal__content">
				<button className="modal__close" onClick={handleClick} name='modal-close' aria-label='close modal'/>
				<div className="modal__expert-box">
					{ data.form && data.form.logo ?
						(
							<div className="modal__svg-box">
								<img src={data.form.logo} alt="NF Group"/>
							</div>
						) :
						<Expert data={data.expert} cls='modal__expert'/>
					}
				</div>
				<div className="modal__form">
					<Form data={data.form} handleSendData={sendData}/>
				</div>
			</div>
		</div>
	);
};

export default Modal;
