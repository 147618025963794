import React from 'react';
import './main.scss';
import Form from "../form/Form";
import Timer from "../timer/Timer";
import Slider from "../slider/Slider";
import Expert from "../expert/Expert";
import SliderFull from "../slider/full/SliderFull";
import Whatsapp from "../whatsapp/Whatsapp";

const Main = ({ data, sendData }) => {
	const [currentData] = React.useState(data);
	const [fullscreenPopup, setFullscreenPopup] = React.useState(false);
	const [fullscreenIndex, setFullscreenIndex] = React.useState(0);

	const updateFullScreenSlider = (idx) => {
		setFullscreenPopup(true);
		setFullscreenIndex(idx);
	};

	return (
		<main className={`main ${data.v_2 ? 'main--v-2' : ''} ${data.wa_bot ? 'main--wa-bot' : ''}`}>
			{ currentData.timer && currentData.timer.show && <Timer cls={`main__timer ${currentData.timer.v_2 ? 'timer--v-2' : ''}`} text={currentData.timer.text} dataEnd={currentData.timer.end}/> }
			{ data.title && <div className={`main__title ${data.v_2 ? 'main__title--v-2' : ''}`}>{data.title}</div> }
			{ currentData.whatsapp && <Whatsapp data={currentData.whatsapp} cls='main__whatsapp'/> }
			<div className={`main__form-and-slider ${currentData.form && currentData.form.right_image ? 'main__form-and-slider--with-right-image' : ''}`}>
				{ data.slides && data.slides.length ?
					<Slider slides={data.slides} cls='main__slider' fullscreen={(idx) => updateFullScreenSlider(idx)} /> :
					null
				}
				{ data.expert && <Expert data={data.expert} cls='main__expert expert--main'/> }
				{ currentData.form && <Form data={currentData.form} image={currentData.form.image || false} cls='main__form' handleSendData={sendData}/> }
				{ currentData.form && currentData.form.right_image ?
					<div className='main__form-image-box'>
						<img src={currentData.form.right_image} alt="NF Expert"/>
					</div> : null
				}
			</div>
			{ fullscreenPopup && data.slides && data.slides.length ?
				<SliderFull slides={data.slides} startSlide={fullscreenIndex} closePopup={() => setFullscreenPopup(false)}/> :
				null
			}
		</main>
	);
};

export default Main;
