import React from 'react';
import './backgrond.scss';
import imagesLoaded from 'imagesloaded';
import { displacementSlider } from '../../utils/bgtree';
import { displacementSliderV1 } from '../../utils/bgtreev1';

const Background = ({ slides, time, cb, v2, idx }) => {
  const parent = React.useRef();
  const slide = React.useRef();
  const [dS, setDs] = React.useState(null);
  slide.current = [];

  const addToRefs = (el) => {
    if (el && !slide.current.includes(el)) {
      slide.current.push(el);
    }
  };

  React.useEffect(() => {
    if (dS && dS.changeTo) {
      dS.changeTo(idx);
    }
  }, [idx]);

  React.useEffect(() => {
    imagesLoaded(slide.current, () => {
      if (v2) {
        new displacementSlider({
          parent: parent.current,
          images: slide.current,
          time,
          duration: 3,
        });
        cb();
      } else {
        setDs(
          new displacementSliderV1({
            parent: parent.current,
            images: slide.current,
            time,
            duration: 3,
          })
        );
        cb();
      }
    });
  }, []);

  return (
    <div className='background' ref={parent} data-time={time}>
      { slides && slides.length ? slides.map((el, index) =>
          <img ref={addToRefs} src={el.image} alt="KF Expert" key={el.image + index}/>
        ) :
        null
      }
    </div>
  );
};

export default Background;
