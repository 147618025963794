import React from 'react';
import './expert.scss';

const Expert = ({ data, cls }) => {
	return (
		<div className={`expert ${cls ? cls: ''}`}>
			<div className="expert__image">
				{ data.image && <img src={data.image} alt={data.name}/> }
			</div>
			<div className="expert__info">
				<div className="expert__name">{data.name}</div>
				<div className="expert__position">{data.position}</div>
			</div>
		</div>
	);
};

export default Expert;
